<template>
<div class="CghtView">
    <div id="subdiv" v-if="contract !=null" >
        <div v-if="contract.content !=null && contract.content!=''">
            <div id="printdiv"><Button type="warning" @click="printcontent">点击打印</Button></div>
            <div id="contentdiv"  v-html="contract.content"></div>
        </div>
        <div v-else ><span style="color: red;font-size: 20px;">模板内容还没有编写喔!</span></div>
    </div>
    <div v-else>正在读取模板内容...</div>
</div>

</template>

<script>
import {getPrinters,public_print,public_print_with_dir} from '@/zsjs/zskjprinter.js'
export default {
name: "CghtView",
props: {
},
data(){
    return {
        contractid:window.atob(this.$route.query.contractid),
        userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
        contract:null
    }
},
mounted(){
    this.querytemplate()
},
methods:{
    querytemplate(){
        let posturl = "/api/checkdept/contract/get"

        let postdata={
            id: this.contractid,
        }

        this.$Loading.start();
        this.$axios({
            method:'post',
            url:posturl,
            data:postdata,
            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
            transformRequest: function(obj) {
                var str = [];
                for(var p in obj){
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
                return str.join("&");
            }
            }).then(function(res){
            if(res.data.resultCode ==0){
                this.$Loading.finish();
                console.log(res)
                if(res.data.data.totalnumber == 0){
                    this.$Loading.error();
                    this.$Message.error({content:res.data.msg, duration:3})
                }
                else{
                    this.contract = res.data.data
                    console.log(this.contract)
                }
            }
            else{
                this.$Loading.error();
                this.$Message.error({content:res.data.msg, duration:3})
            }
            }.bind(this));   
    },
    printcontent(){
        public_print_with_dir("contentdiv",1,this.contract.direction);
    }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .CghtView {
    margin:20px auto;
    padding: 5%;
    }
#subdiv {
    margin: 0 auto;
}
#printdiv {
    margin:2px auto;
    width:1100px;
    text-align: right;
}
#contentdiv {
    margin:0 auto;
    width:1100px;
    text-align: left;
    padding: 5px 10px;
    border: 1px solid lightgray;
}

#contentdiv p{
    text-align: left;
}
</style>  